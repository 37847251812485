import produce from "immer";
import {GET_CUSTOMER_LOGS} from "../actions/customerLogActions";

const initialState = {
  open: 0,
  closed: 0
};

const customerLogReducer = (state = initialState, action) => {
  switch (action.type) {

  case GET_CUSTOMER_LOGS: {
    const {openCount, closedCount} = action.payload;
    return produce(state, (draft) => {
      draft.open = openCount;
      draft.closed = closedCount;
    });
  }

  default: {
    return state;
  }
  }
};

export default customerLogReducer;
