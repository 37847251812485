import authService from "src/services/authService";
import axios from "../utils/axios";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const UPDATE_USER = "@account/update-user";

export function login (personalNumber) {
  return async (dispatch) => {
    try {
      dispatch({type: LOGIN_REQUEST});

      const user = await authService.loginWithPersonalNumber(personalNumber);

      if (user === 500) {
        throw new Error();
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      return user;
    } catch (error) {
      dispatch({type: LOGIN_FAILURE});
      throw error;
    }
  };
}

export function setUserData (user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function updateUserData () {
  return async (dispatch) => {
    try {

      const {data} = await axios.get("/me");
      const user = data;

      dispatch({
        type: UPDATE_USER,
        payload: {
          user
        }
      });

    } catch (error) {
      console.log(error);
      authService.logout();
    }
  };
}

export function logout () {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}
