import axios from "../utils/axios";

export const GET_RESELLERS = "@resellers/get-resellers";
export const RESELLER_MODAL = "@resellers/reseller-modal";

export function setResellerData () {
  return async (dispatch) => {
    try {
      const {data} = await axios.get(`/reseller-list`);
      const {resellers} = data;

      if (resellers === 500) {
        throw new Error();
      }

      dispatch({
        type: GET_RESELLERS,
        payload: {
          resellers
        }
      });

    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function setResellerModal (modalOpen) {
  return (dispatch) => dispatch({
    type: RESELLER_MODAL,
    payload: {
      modalOpen
    }
  });
}
