import React from "react";

function Logo () {
  return (
    <img
      alt="Logo"
      src="/static/identio-logo.svg"
      style={{maxHeight: 30}}
    />
  );
}

export default Logo;
