import React, {useState} from "react";
import {
  Portal,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel, DialogActions, Button
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setResellerModal} from "../actions/resellerActions";
import axios from "../utils/axios";
import {updateUserData} from "../actions/accountActions";

const SetActiveReseller = () => {
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.account);
  const resellers = useSelector((state) => state.resellers);
  const modalState = resellers.modalOpen;
  const availableResellers = resellers.resellers.filter(reseller => user && user.reseller_ids.indexOf(reseller.id) !== -1);
  const activeStore = user && user.reseller_id && user.reseller_id.toString();
  const [value, setValue] = useState(activeStore);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const updateActiveReseller = async () => {
    try {
      await axios.post("/user-set-reseller", {
        reseller_id: value
      });

      await dispatch(setResellerModal(!modalState));
      await dispatch(updateUserData());

    } catch (error) {
      console.log(error);
    }
  };

  if (!modalState) {
    return null;
  }

  return (
    <Portal>
      <Dialog
        open={modalState}
        onClose={() => dispatch(setResellerModal(!modalState))}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
                Välj din Butik
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Butiker</FormLabel>
            <RadioGroup aria-label="butiker" name="butiker1" value={value} onChange={handleChange}>
              {availableResellers.map(reseller => (
                <FormControlLabel
                  key={reseller.id}
                  value={reseller.id.toString()}
                  control={<Radio />}
                  label={reseller.name} />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => updateActiveReseller()} color="primary">
                 Spara
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
};

export default SetActiveReseller;
