import axios from "src/utils/axios";

export const GET_CUSTOMER_LOGS = "@customerLogs/get-logs";

export function setCustomerLogData () {
  return async (dispatch) => {
    try {
      const openLogData = await axios.get(`/log-count?status=OPEN`);
      const closedLogData = await axios.get(`/log-count?status=CLOSED`);

      dispatch({
        type: GET_CUSTOMER_LOGS,
        payload: {
          openCount: openLogData.data.count,
          closedCount: closedLogData.data.count
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
}
