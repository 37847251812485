import produce from "immer";
import {GET_RESELLERS, RESELLER_MODAL} from "../actions/resellerActions";

const initialState = {
  resellers: [],
  modalOpen: false
};

const resellerReducer = (state = initialState, action) => {
  switch (action.type) {

  case GET_RESELLERS: {
    const {resellers} = action.payload;
    return produce(state, (draft) => {
      draft.resellers = resellers;
    });
  }

  case RESELLER_MODAL: {
    const {modalOpen} = action.payload;

    return produce(state, (draft) => {
      draft.modalOpen = modalOpen;
    });
  }

  default: {
    return state;
  }
  }
};

export default resellerReducer;
