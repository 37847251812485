/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {useEffect} from "react";
import {useLocation, matchPath} from "react-router";
import {useSelector} from "react-redux";
import {Link as RouterLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from "@material-ui/core";
import {
  ShoppingCart as ShoppingCartIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Bell as BellIcon,
  PhoneCall
} from "react-feather";
import ListIcon from "@material-ui/icons/List";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import SmsIcon from '@material-ui/icons/Sms';
import { CreditCard } from "@material-ui/icons";


function renderNavItems ({items, ...rest}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({acc, item, ...rest}),
        []
      )}
    </List>
  );
}

function reduceChildRoutes ({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.hide) {
    return acc;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        value={item.value}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  }
}));

function NavBar ({openMobile, onMobileClose}) {
  const classes = useStyles();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const logNumber = useSelector((state) => state.logs.open);
  const isAdmin = account.user.permissions.includes("ADMIN") || account.user.permissions.includes("SUPER");
  const isCustomerService = account.user.permissions.includes("CUSTOMER_SERVICE");

  const navConfig = [
    {
      subheader: "Ordrar",
      admin: false,
      items: [
        {
          title: "ID-Skydd Order",
          icon: ShoppingCartIcon,
          href: "/order/register"
        },
        {
          title: "Larm Order",
          icon: BellIcon,
          href: "/alarm/register",
        },
        {
          title: "Larmknapp Order",
          icon: PhoneCall,
          href: "/button/register",
        }
      ]
    },
    {
      subheader: "Rapporter",
      admin: false,
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/dashboard"
        }
      ]
    },
    {
      subheader: "Kunder",
      admin: true,
      items: [
        {
          title: "Alla Kunder",
          icon: UsersIcon,
          href: "/customers"
        },
        {
          title: "ID-Skydd Kunder",
          icon: UsersIcon,
          href: "/customers/identity",
        },
        {
          title: "Hemlarm Kunder",
          icon: UsersIcon,
          href: "/customers/alarm",
        },
        {
          title: "Larmknapp Kunder",
          icon: UsersIcon,
          href: "/customers/alarmButton",
        }
      ]
    },
    {
      subheader: "Kundtjänst",
      admin: true,
      customerService: true,
      items: [
        {
          title: "Kundärenden",
          icon: ListIcon,
          value: logNumber,
          href: "/admin/customerlog"
        },
      ]
    },
    {
      subheader: "Admin",
      admin: true,
      items: [
        {
          title: "Manuell Kredit",
          icon: CreditCard,
          href: "/admin/declined-credit-reports"
        },
        {
          title: "Pending Ordrar",
          icon: ListIcon,
          href: "/admin/orders/pending"
        },
        {
          title: "Användare",
          icon: UsersIcon,
          href: "/admin/users",
          items: [
            {
              title: "Alla Användare",
              href: "/admin/users"
            },
            {
              title: "Skapa Användare",
              href: "/admin/users/create"
            }
          ]
        },
        {
          title: "Återförsäljare",
          icon: ShoppingCartIcon,
          href: "/admin/resellers",
          items: [
            {
              title: "Alla Återförsäljare",
              href: "/admin/resellers"
            },
            {
              title: "Skapa Återförsäljare",
              href: "/admin/resellers/create"
            }
          ]
        },
        {
          title: "Exportera data",
          icon: ListIcon,
          href: "/admin/export/sales"
        },
        {
          title: "Mass SMS",
          icon: SmsIcon,
          href: "/admin/sms"
        }
      ]
    }
  ];

  const sellerRoutes = navConfig.filter(config => config.admin === false);
  const customerServiceRoutes = navConfig.filter(config => config.customerService === true);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {isAdmin ?
            navConfig.map(config => (
              <List
                key={config.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {config.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({items: config.items, pathname: location.pathname})}
              </List>
            )) :
            sellerRoutes.map(config => (
              <List
                key={config.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {config.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({items: config.items, pathname: location.pathname})}
              </List>
            ))
          }
          {isCustomerService &&
            customerServiceRoutes.map(config => (
              <List
                key={config.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {config.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({items: config.items, pathname: location.pathname})}
              </List>
            ))
          }
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{paper: classes.mobileDrawer}}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{paper: classes.desktopDrawer}}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
