import React, {useState} from "react";
import {TextField, makeStyles} from "@material-ui/core";
import axios from "../utils/axios";
import {useHistory} from "react-router";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "4px"
  }
}));

const SearchCustomer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const {enqueueSnackbar} = useSnackbar();

  const handleSearch = async (value) => {
    try {
      const data = await axios.get(`/customer-get-id?personal_number=${value}`);
      if (data.status === 200) {
        history.push(`/customers/${data.data}`);
        setSearchValue("");
      }
    } catch (err) {
      enqueueSnackbar(err.response.data.error, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      });
      setSearchValue("");
    }


  };

  const handleQueryChange = (event) => {
    event.persist();
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      setSearchValue(event.target.value);
      handleSearch(event.target.value);
    }
  };

  return (
    <TextField
      className={classes.root}
      onChange={handleQueryChange}
      onKeyDown={handleKeyDown}
      label="Sök personnummer"
      placeholder={"12 siffror"}
      value={searchValue}
      variant="outlined"
    />
  );
};

export default SearchCustomer;
