import React, {
  useEffect,
  useState
} from "react";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import SplashScreen from "src/components/SplashScreen";
import {setUserData, logout} from "src/actions/accountActions";
import authService from "src/services/authService";
import {setResellerData} from "../actions/resellerActions";
import {setCustomerLogData} from "../actions/customerLogActions";

function Auth ({children}) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.loginInWithToken();
        await dispatch(setUserData(user));
        await dispatch(setResellerData());

        const isAdmin = user.permissions.includes("ADMIN") || user.permissions.includes("SUPER") || user.permissions.includes("CUSTOMER_SERVICE");;

        if (isAdmin) {
          await dispatch(setCustomerLogData());
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
