import React from "react";
import {Box, ButtonBase, SvgIcon, Tooltip, Typography} from "@material-ui/core";
import {ShoppingBag as StoreIcon} from "react-feather";
import {useDispatch, useSelector} from "react-redux";
import {setResellerModal} from "../../../actions/resellerActions";

const SelectReseller = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const {resellers} = useSelector((state) => state.resellers);

  const modalState = resellers.modalOpen;

  const activeReseller = resellers.filter(reseller => reseller.id === account.user.reseller_id);
  const activeResellerName = activeReseller[0] ? activeReseller[0].name : "Ingen butik vald";

  return (
    <Tooltip title="Välj butik">
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={() => dispatch(setResellerModal(!modalState))}
      >

        <SvgIcon fontSize="small" style={{marginRight: 5}}>
          <StoreIcon />
        </SvgIcon>
        <Typography
          variant="h6"
          color="inherit"
        >
          {`Butik: ${activeResellerName}`}
        </Typography>

      </Box>
    </Tooltip>
  );
};

export default SelectReseller;
