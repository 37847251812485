import {combineReducers} from "redux";
import accountReducer from "./accountReducer";
import resellerReducer from "./resellerReducer";
import customerLogReducer from "./customerLogReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  resellers: resellerReducer,
  logs: customerLogReducer
});

export default rootReducer;
